import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import ReactMarkdown from 'react-markdown';
import { List, ListItem } from '@mui/material';

const AGBPage = () => {
  const theme = useTheme();

  const agbContent = `
# Allgemeine Vertragsbedingungen der MediConsult GmbH
*Zuletzt inhaltlich aktualisiert am: 27. Oktober 2024*

## §1 Geltungsbereich

Diese Vertragsbedingungen gelten für alle Geschäftsbeziehungen und Auftraggeber der MediConsult GmbH.
Diese Vertragsbedingungen gelten insbesondere für Verträge unserer Servicedienstleistungen. Sofern nichts anderes vereinbart, gelten die AGB in der zum Zeitpunkt der Bestellung des Kunden gültigen bzw. jedenfalls in der ihm zuletzt in Textform mitgeteilten Fassung.
Diese Vertragsbedingungen gelten ausschließlich. Abweichende, entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen des Kunden werden nur dann und insoweit Vertragsbestandteil, als wir ihrer Geltung ausdrücklich zugestimmt haben.

Mit der Unterzeichnung eines Auftragsformulars nimmt der Auftraggeber zur Kenntnis, dass die Anzeigenwerbung durch einen Mitarbeiter der MediConsult GmbH erfolgt. Mit seiner Unterschrift bescheinigt der Auftraggeber, dass er für diesen Vertragsabschluss zeichnungsberechtigt ist. Der Auftraggeber erklärt seine ausdrückliche Einwilligung, dass die MediConsult GmbH ihn und die durch ihn vertretende juristische Person zu Werbezwecken anrufen, sowie elektronische Post einschließlich SMS zu Werbezwecken übermitteln darf.

## §2 Nebenabsprachen

Zusätzlich oder abweichende Vereinbarungen von diesen Vertragsbedingungen, welche nicht auf dem Auftragsformular vermerkt sind, bedürfen zu ihrer Gültigkeit der schriftlichen Bestätigung durch die MediConsult GmbH. Mündliche Nebenabreden gelten als nicht erfolgt.

## §3 Auftragsbestätigung und Rechnung

Binnen zwei Wochen nach Erteilung des Auftrages erhält der Auftraggeber eine Rechnung zugesendet. Diese Rechnung gilt gleichzeitig als Auftragsbestätigung. Der Rechnungsbetrag ist spätestens zwei Wochen nach Erhalt der Rechnung zu begleichen. Mit der Überweisung ODER der Unterzeichnung des Auftragsformulars bestätigt der Kunde den Auftrag.

## §4 Zahlungen und Schriftverkehr

Rückfragen, Mitteilungen und dgl. sind ausschließlich an die MediConsult GmbH unter Angabe der Auftragsnummer zu richten. Zahlungen sind auf namhaft gemachte Konten von MediConsult GmbH zu leisten.

## §5 Kündigung

Kündigt der Auftraggeber den Vertrag, so hat die MediConsult GmbH einen Anspruch auf die vereinbarte Vergütung. Sämtliche Dienstleistungen und Eventtickets sind vom Umtausch ausgeschlossen. Die Kündigung an die MediConsult GmbH muss schriftlich erfolgen.

## §6 Auflagengarantie

Die MediConsult GmbH garantiert Anzeigenkäufern eine Mindestauflage von 100.000 Stück pro Kauf einer Werbeanzeige. Der Zeitpunkt der Veröffentlichung und die Verteilung der Exemplare richtet sich nach der Bestellmenge der Lehrbücher und Aktivhefte.

## §7 Druckunterlagen und Korrekturabzug

Für die rechtzeitige Lieferung des Anzeigentextes und der einwandfreien Unterlagen ist der Auftraggeber verantwortlich. Liegen solche Unterlagen bis Redaktionsschluss nicht vor, so ist der Verlag berechtigt, die Anzeige nach den ihm vorliegenden Unterlagen bestmöglich zu gestalten und in Druck zu geben. Gewährleistungs- und Schadenersatzansprüche stehen dem Auftraggeber dann ebenso wenig wie ein Rückhaltungsrecht zu. Aufgrund technischer Erfordernisse werden Schmuckfarben aus der Vierfarbskala aufgebaut. Dabei können Farbabweichungen auftreten. Vor Drucklegung erhält der Auftraggeber einen Korrekturabzug, welcher innerhalb der darauf angegebenen Frist überprüft und zurückgesandt werden muss. Dem Auftraggeber obliegt die Verantwortung für die Richtigkeit der zurückgesandten Probeabzüge. Der Verlag berücksichtigt die Fehlerkorrekturen, die ihm innerhalb der vorgenannten Frist nach Erhalt der Probezüge mitgeteilt werden. Nach Ablauf der Frist können Änderungen nicht mehr berücksichtigt werden. Bei Nichtmeldung innerhalb der Frist setzt der Verlag die Druckgenehmigung des Auftraggebers voraus.

## §8 Werberechte

Mit der Auftragsbestätigung gehen der Auftraggeber und die MediConsult GmbH ein sich gegenseitig unterstützendes Werbeverhältnis ein.

Mit der Bestätigung des Auftrags erwirbt der Auftraggeber das einjährige Recht zur werblichen Nutzung des MediConsult-Logos auf dessen Social Media-Kanälen und die Bezeichnung als offizieller Unterstützer von MediConsult.
In diesem Zeitraum gibt der Auftraggeber der MediConsult GmbH das Recht, ihn zu Werbezwecken als offiziellen Unterstützer zu benennen.
Die einjährige Veröffentlichung des Unterstützerlogos auf der MediConsult-Website beginnt mit dem Rechnungsdatum.
Der Auftraggeber hat das Recht, einer Veröffentlichung oder der Nutzung zu Werbezwecken jederzeit schriftlich zu widersprechen.

## §9 Gerichtsstand

Gerichtsstand für Streitigkeiten mit Vollkaufleuten im Rahmen ihres Handelsgeschäftes oder mit einer juristischen Person des öffentlichen Rechts ist Hamburg. Der gleiche Gerichtsstand gilt mit Nichtkaufleuten, soweit das Gesetz nichts anderes zwingend vorschreibt, wenn der Auftraggeber keinen allgemeinen Gerichtsstand im Inland hat, nach Vertragsabschluss seinen Wohnsitz oder gewöhnlichen Aufenthalt aus dem Inland verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthaltsort zum Zeitpunkt der Klageerhebung nicht bekannt ist, als vereinbart.

## §10 Geld-zurück-Garantie für neue Mitglieder

Wenn Sie innerhalb von 30 Tagen nach Ihrer Anmeldung nicht aufgenommen werden oder wenn Sie innerhalb dieser Zeit kündigen, erstatten wir Ihnen den vollen Betrag zurück.

## §11 Sonstiges

Der Auftraggeber kann nach der EU-DSGVO eine erteilte Einwilligung zur Datenverarbeitung widerrufen. Dazu reicht eine formlose Mail an folgende Mailadresse: **info@kunstkaleidoskop.de**.
`;

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: '20px',
          maxWidth: '1000px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
          Allgemeine Geschäftsbedingungen
        </Typography>
        <ReactMarkdown
          components={{
            h1: ({ node, ...props }) => (
              <Typography variant="h5" align="center" fontWeight="bold" gutterBottom {...props} />
            ),
            h2: ({ node, ...props }) => (
              <Typography variant="h6" fontWeight="bold" gutterBottom {...props} />
            ),
            p: ({ node, ...props }) => (
              <Typography variant="body1" sx={{ fontSize: '1rem' }} paragraph {...props} />
            ),
            strong: ({ node, ...props }) => <strong {...props} />,
            em: ({ node, ...props }) => <em {...props} />,
            ul: ({ node, ...props }) => (
              <List
                sx={{
                  paddingLeft: '1em',
                  marginTop: 0,
                  marginBottom: 0,
                }}
                {...props}
              />
            ),
            li: ({ node, ...props }) => (
              <ListItem
                sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}
                disableGutters
                disablePadding
              >
                <Typography variant="body1" sx={{ fontSize: '1rem' }} component="span" {...props} />
              </ListItem>
            ),
          }}
        >
          {agbContent}
        </ReactMarkdown>
      </Box>

      <Footer />
    </Box>
  );
};

export default AGBPage;
